import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import store from './store'
import App from './App'
import './style/index.css'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import {
  AWS_REGION,
  SENTRY_DSN,
  identityPoolId,
  userPoolId,
  userPoolWebClientId,
} from './constant'

import { Amplify } from 'aws-amplify'

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    new Sentry.Integrations.Breadcrumbs({ console: false }),
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/edu-pwa\.01ive\.co\.jp/,
      ],
    }),
  ],
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1,
  release: process.env.COMMIT_SHA,
})

Amplify.configure({
  aws_project_region: AWS_REGION,
  Auth: {
    identityPoolId: identityPoolId,
    region: AWS_REGION,
    userPoolId: userPoolId,
    userPoolWebClientId: userPoolWebClientId,
  },
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="*" element={<App />} />
        </Routes>
      </Router>
    </Provider>
  </React.StrictMode>
)

serviceWorkerRegistration.register()
