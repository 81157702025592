import { Amplify, Auth } from 'aws-amplify'
import { attachPolicy } from '../libs/cognito'
import { AWSIoTProvider } from '@aws-amplify/pubsub'
import { AWS_REGION, MQTT_ENDPOINT } from '../constant'
import { AppDispatch } from '../store'

export const UPDATE_IOT_CONNECTION_STATUS = 'UPDATE_IOT_CONNECTION_STATUS'

export const disconnectIoT = () => {
  return async (dispatch: AppDispatch) => {
    try {
      console.log('disconnect to IoT')
      if (Amplify.PubSub) {
        Amplify.PubSub.removePluggable('AWSIoTProvider')
      }
      dispatch({
        type: UPDATE_IOT_CONNECTION_STATUS,
        data: false,
      })
    } catch (error) {
      console.log(error)
      dispatch({
        type: UPDATE_IOT_CONNECTION_STATUS,
        data: false,
      })
      alert('Can not disconnect to IoT')
    }
  }
}

export const connectIoT = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const currentUser = await Auth.currentCredentials()
      if (currentUser.identityId != undefined) {
        dispatch({
          type: UPDATE_IOT_CONNECTION_STATUS,
          data: false,
        })

        console.log('Connect to IoT')
        console.log(
          `AWS IoT logging, region:${AWS_REGION}, MQTT Endpoint: ${MQTT_ENDPOINT}`
        )
        if (Amplify.PubSub) {
          Amplify.PubSub.removePluggable('AWSIoTProvider')
        }
        attachPolicy(currentUser.identityId)
        Amplify.addPluggable(
          new AWSIoTProvider({
            aws_pubsub_region: AWS_REGION,
            aws_pubsub_endpoint: MQTT_ENDPOINT,
          })
        )
        dispatch({
          type: UPDATE_IOT_CONNECTION_STATUS,
          data: true,
        })
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: UPDATE_IOT_CONNECTION_STATUS,
        data: false,
      })
      alert('Can not connect to IoT')
    }
  }
}
