const clip = (data: any = [], limit: number) => {
  let tmp = data.map((v: number) => {
    if (v > limit) {
      return limit
    } else if (v < -limit) {
      return -limit
    }
    return v
  })
  return tmp
}

var range = function (start: number, stop: number, step: number) {
  step = step || 1
  var arr: any = []
  for (var i = start; i < stop; i += step) {
    arr.push(i)
  }
  return arr
}

function window_segment(
  data: [],
  win_len: number,
  shift_len: number,
  fs: number
) {
  fs = Math.round(fs)
  const data_len = data.length
  const win = Math.round(win_len * fs)
  const shift = Math.round(shift_len * fs)
  const win_start = range(0, data_len - win + shift, shift)

  let final = win_start.map((v: number, i: number) => {
    if (v + win < data_len) {
      return [v, v + win]
    }
    return undefined
  })

  if (final[-1] == undefined) {
    final = final.slice(0, -1)
  }

  return final
}

const average = (arr: []) => {
  return arr.reduce((a, b) => a + b, 0) / arr.length
}

function varience(arr: any = []) {
  if (!arr.length) {
    return 0
  }
  const sum = arr.reduce((acc: number, val: number) => acc + val)
  const { length: num } = arr
  const median = sum / num
  let variance = 0
  arr.forEach((num: number) => {
    variance += (num - median) * (num - median)
  })
  variance /= num
  return variance
}

const moving_average = (data: any = [], window: number = 3) => {
  var i = 0
  var pad = Math.round(i / 2)
  let ma_data: any = []
  while (i < data.length - window + 1) {
    var data_segment = data.slice(i, i + window)
    var sum = data_segment.reduce(
      (partialSum: number, a: number) => partialSum + a,
      0
    )
    var window_average: any = Math.round((sum / window) * 100) / 100
    ma_data.push(window_average)
    i += 1
  }

  for (var j = 0; j < pad; j += 1) {
    ma_data[j] = ma_data[pad]
    ma_data.push(ma_data[-1])
  }

  return ma_data
}

const count_in_array = (arr: any, v: number) => {
  return arr.filter((x: number) => x == v).length
}

export const head_shaking_count = (
  data: any = [],
  total_second: number = 10
) => {
  const THRESH_ANGLE = 30,
    THRESH_VAR = 20,
    CENTER = 0,
    LEFT = -1,
    RIGHT = 1,
    WIN_LEN = 0.5,
    SHIFT_LEN = 0.5

  data = moving_average(moving_average(clip(data, 120), 4), 3)
  const sample_rate = Math.round(data.length / total_second)

  const win_slices = window_segment(data, WIN_LEN, SHIFT_LEN, sample_rate)

  let head_directions = win_slices.map((v: any, i: number) => {
    const win_data = data.slice(v[0], v[1])
    const win_var = varience(win_data)
    const _max = Math.max.apply(Math, win_data)
    const _min = Math.min.apply(Math, win_data)

    if (win_var > THRESH_VAR) {
      if (_min < -THRESH_ANGLE) {
        return LEFT
      } else if (_max > THRESH_ANGLE) {
        return RIGHT
      }
      return CENTER
    } else {
      if (average(win_data) < -THRESH_ANGLE) {
        return LEFT
      } else if (average(win_data) > THRESH_ANGLE) {
        return RIGHT
      }
      return CENTER
    }
  })

  let mas = [head_directions[0]]

  for (var i = 0; i < head_directions.length - 1; i += 1) {
    if (head_directions[i] != head_directions[i + 1]) {
      mas.push(head_directions[i + 1])
    }
  }

  return count_in_array(mas, -1) + count_in_array(mas, 1)
}
