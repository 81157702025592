import {
  MODEL_NOT_LOAD,
  MODEL_LOADING,
  MODEL_LOADED,
  MODEL_LOAD_FAILED,
} from './constant'

import {
  LOAD_LANDMARK_MODEL_FAILED,
  LOAD_LANDMARK_MODEL_START,
  LOAD_LANDMARK_MODEL_SUCCESS,
  LOAD_VPPG_MODEL_START,
  LOAD_VPPG_MODEL_SUCCESS,
  LOAD_VPPG_MODEL_FAILED,
  LOAD_STRESS_MODEL_START,
  LOAD_STRESS_MODEL_SUCCESS,
  LOAD_STRESS_MODEL_FAILED,
  MODEL_READY,
} from './actions/model'

import {
  EMOTION_CHANGE,
  USER_LOGIN_FAILED,
  USER_LOGIN_START,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
} from './actions/user'

import { UPDATE_IOT_CONNECTION_STATUS } from './actions/iot'

import {
  START_CAPTURE,
  START_STOP_BTN,
  STOP_CAPTURE,
  UPDATE_WEBCAM_STATUS,
} from './actions/camera'

export type AWSUserInfo = {
  email: string
  id: string
  username: string
  attributes: {
    ['custom:admin']: string
    ['custom:company']: string
    ['custom:mode']: string
    ['custom:subject_id2']: string
    email: string
    email_verified: boolean
    sub: string
  }
}

const initialState = {
  isCameraReady: false,
  isLogin: false,
  loginLoading: false,
  loginFailed: false,
  landmarkModelStatus: MODEL_NOT_LOAD,
  vppgModelStatus: MODEL_NOT_LOAD,
  stressModelStatus: MODEL_NOT_LOAD,
  isCaptureReady: false,
  isCapturing: false,
  webcamStatus: false,
  user: {} as AWSUserInfo['attributes'],
  iotConnectionStatus: false,
  latestEmotion: 999,
}
type State = typeof initialState

export const rootReducer = (
  state: State = initialState,
  action: any
): State => {
  switch (action.type) {
    case LOAD_LANDMARK_MODEL_START:
      return {
        ...state,
        ...{ landmarkModelStatus: MODEL_LOADING },
      }

    case LOAD_LANDMARK_MODEL_SUCCESS:
      return {
        ...state,
        ...{ landmarkModelStatus: MODEL_LOADED },
      }

    case LOAD_LANDMARK_MODEL_FAILED:
      return {
        ...state,
        ...{ landmarkModelStatus: MODEL_LOAD_FAILED },
      }

    case LOAD_VPPG_MODEL_START:
      return {
        ...state,
        ...{ vppgModelStatus: MODEL_LOADING },
      }

    case LOAD_VPPG_MODEL_SUCCESS:
      return {
        ...state,
        ...{ vppgModelStatus: MODEL_LOADED },
      }

    case LOAD_VPPG_MODEL_FAILED:
      return {
        ...state,
        ...{ vppgModelStatus: MODEL_LOAD_FAILED },
      }

    case LOAD_STRESS_MODEL_START:
      return {
        ...state,
        ...{ stressModelStatus: MODEL_LOADING },
      }

    case LOAD_STRESS_MODEL_SUCCESS:
      return {
        ...state,
        ...{ stressModelStatus: MODEL_LOADED },
      }

    case LOAD_STRESS_MODEL_FAILED:
      return {
        ...state,
        ...{ stressModelStatus: MODEL_LOAD_FAILED },
      }

    case MODEL_READY:
      return {
        ...state,
        ...{ isCaptureReady: true },
      }

    case USER_LOGIN_START:
      return {
        ...state,
        ...{
          user: action.data,
          isLogin: false,
          loginLoading: true,
          loginFailed: false,
        },
      }

    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        ...{ user: action.data, isLogin: true, loginLoading: false },
      }

    case USER_LOGIN_FAILED:
      return {
        ...state,
        ...{
          user: {} as AWSUserInfo['attributes'],
          isLogin: false,
          loginLoading: false,
          loginFailed: true,
        },
      }

    case USER_LOGOUT:
      return {
        ...state,
        ...{
          user: {} as AWSUserInfo['attributes'],
          isLogin: false,
          isCapturing: false,
          latestEmotion: 999,
        },
      }

    case START_STOP_BTN:
      return {
        ...state,
        ...{ isCameraReady: action.data },
      }

    case UPDATE_WEBCAM_STATUS:
      return {
        ...state,
        ...{ webcamStatus: action.data },
      }

    case UPDATE_IOT_CONNECTION_STATUS:
      return {
        ...state,
        ...{ iotConnectionStatus: action.data },
      }

    case EMOTION_CHANGE:
      return {
        ...state,
        ...{ latestEmotion: action.data },
      }

    case START_CAPTURE:
      return {
        ...state,
        ...{ isCapturing: true },
      }

    case STOP_CAPTURE:
      return {
        ...state,
        ...{ isCapturing: false },
      }

    default:
      return state
  }
}
