import { useEffect, useRef } from 'react'
import { PubSub } from '@aws-amplify/pubsub'
import { MapDispatchToProps, MapStateToProps, connect } from 'react-redux'
import FPSProcessor from '../libs/FPSProcessor'
import tensorStore from '../libs/tensorStore'
import attentionStore from '../libs/attentionStore'
import stressProcessor from '../libs/stressProcessor'
import { iirFilter } from '../libs/DSP'
import * as Sentry from '@sentry/react'
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";

import { cumsum, reshape } from '@tensorflow/tfjs'
import {
  IOT_TOPIC,
  COLLECT_PULSE,
  SENTRY_TRANSACTION_PUSH_IOT,
  SENTRY_CUSTOM_METRICS_FPS,
  SENTRY_CUSTOM_METRICS_RAW_SIGNAL_LENGTH,
  SENTRY_CUSTOM_METRICS_SUCCEED,
  BACKUP_BUCKET,
} from '../constant'
import { connectIoT } from '../actions/iot'
import { updateEmotion } from '../actions/user'
import { RootState } from '../store'
import { useUploadQueue } from '../libs/minio'
import { Auth } from 'aws-amplify'

type ConnectedProps = Pick<
  RootState,
  'isLogin' | 'isCameraReady' | 'isCapturing' | 'user' | 'webcamStatus'
>

type DispatchProps = {
  connectIoT: typeof connectIoT
  updateEmotion: typeof updateEmotion
}

type OwnProps = {}

type Props = OwnProps & ConnectedProps & DispatchProps

const Cronjob = ({ user, isCapturing, webcamStatus, connectIoT, updateEmotion }: Props) => {
  const pushUploadQueue = useUploadQueue(user, [webcamStatus])

  const filterRawSignal = (fps: number, totalFrames: number) => {
    const pltData = tensorStore.rppgPltData
    const rppgCumsum = cumsum(reshape(pltData, [-1, 1]), 0).dataSync()
    const iirFilterdData = iirFilter
      .filtfilt(rppgCumsum)
      .slice(0, rppgCumsum.length - 2 * fps)
    return iirFilterdData.slice(-totalFrames)
  }

  const IoTIntervalIdRef = useRef<NodeJS.Timer>()
  useEffect(() => {
    const PushToIoT = async (data: {
      company_id: string
      user_id: string
      stress: number
      attention: number
      present_time: number
      timestamp: number
    }) => {
      const topicName = `${IOT_TOPIC}/${user.email.split('@')[0]}`
      PubSub.publish(topicName, data).then(
        () => {},
        (err) => {
          console.log(err)
          console.log('FAILED: Send to IoT at ', Date())
          connectIoT()
        }
      )
    }

    if (isCapturing) {
      IoTIntervalIdRef.current = setInterval(async () => {
        const endTime = new Date(new Date().getTime() - 1000 * 2)
        const endTimeTS = endTime.getTime()
        const startTimeTS = endTimeTS - 8 * 1000
        const FPSMetrics = FPSProcessor.getFPSData(startTimeTS, endTimeTS)

        const fps = FPSMetrics.fps

        const [present_percentage, , head_stability] = attentionStore.stat(
          startTimeTS,
          endTime.getTime()
        )

        const rawSignal = filterRawSignal(fps, FPSMetrics.totalImages)

        const transaction = Sentry.startTransaction({
          name: SENTRY_TRANSACTION_PUSH_IOT,
        })
        transaction.setMeasurement(SENTRY_CUSTOM_METRICS_FPS, fps, '')
        transaction.setMeasurement(
          SENTRY_CUSTOM_METRICS_RAW_SIGNAL_LENGTH,
          rawSignal.length,
          ''
        )

        if (fps > 10 && rawSignal.length >= fps * 6) {
          const collectable = present_percentage > 0.6 && COLLECT_PULSE
          if (collectable) {
            const captureInfor = {
              ...FPSMetrics,
              ...{
                startTime: startTimeTS,
                endTime: endTimeTS,
                user_id: user.email,
              },
            }

            const blob = new Blob(
              [JSON.stringify({ captureInfor, rawSignal })],
              { type: 'application/json' },
            )

            const fileName = `${startTimeTS}-${endTimeTS}_pulse.json`;
            const path = `${user['custom:company']}/${user.email}/${endTime.getFullYear()}/${
              endTime.getMonth() + 1
            }/${endTime.getDate()}/${fileName}`
            const command = new PutObjectCommand({
              Bucket: BACKUP_BUCKET,
              Key: path,
              Body: blob,
              ContentEncoding: "base64",
              ContentType: "application/json",
            });

            const currentUser = await Auth.currentCredentials();
            const s3 = new S3Client({
              region: "ap-northeast-1",
              credentials: currentUser,
            });
            s3.send(command, function (err: any, data: any) {
              if (err) {
                console.log(err);
                console.log("Error uploading data: ", data);
              } else {
                console.log("succesfully uploaded!!!");
              }
            });
          }

          const stress = await stressProcessor.predict(rawSignal, fps)

          const attention_score =
            Math.round(
              (1 - (1 - present_percentage) - head_stability * 0.15) * 100
            ) / 100

          const stressType = quantize_stress(stress)

          const finalType = calculateFourtypes(
            stressType,
            attention_score,
            present_percentage
          )

          updateEmotion(finalType)

          const forcastData = {
            company_id: user['custom:company'],
            user_id: user.email,
            attention: attention_score,
            present_time: present_percentage,
            stress: stress,
            timestamp: endTimeTS,
            head_stability: head_stability,
          }

          PushToIoT(forcastData)
          transaction.setMeasurement(SENTRY_CUSTOM_METRICS_SUCCEED, 1, '')
          if (!collectable) {
            transaction.finish()
          }
        } else {
          console.log(`not enough data: got ${fps} : ${rawSignal.length}`)
          transaction.setMeasurement(SENTRY_CUSTOM_METRICS_SUCCEED, 0, '')
          transaction.finish()
        }
      }, 1000 * 10)
    } else {
      clearInterval(IoTIntervalIdRef.current!)
      IoTIntervalIdRef.current = undefined
    }

    return () => {
      if (IoTIntervalIdRef.current) {
        clearInterval(IoTIntervalIdRef.current)
      }
    }
  }, [connectIoT, isCapturing, pushUploadQueue, updateEmotion, user])

  return <></>
}

const quantize_stress = (stress: number) => {
  if (stress < 0.4) {
    return 1
  } else if (stress <= 0.8) {
    return 2
  } else if (stress <= 1) {
    return 3
  }
  return 999
}

const calculateFourtypes = (
  stressType: number,
  attention: number,
  present_percentage: number
) => {
  let attentionType
  if (present_percentage < 0.6) {
    attentionType = 999
  } else if (attention >= 0.6) {
    attentionType = 1
  } else {
    attentionType = 0
  }

  if (attentionType == 999 || stressType == 999) {
    return 999
  }

  if (attentionType == 1 && (stressType == 1 || stressType == 2)) {
    return 1
  }

  if (attentionType == 1 && stressType == 3) {
    return 2
  }

  if (attentionType == 0 && (stressType == 1 || stressType == 2)) {
    return 3
  }

  if (attentionType == 0 && stressType == 3) {
    return 4
  }

  return 999
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: any
) => {
  return {
    connectIoT: () => dispatch(connectIoT()),
    updateEmotion: (emotion: any) => dispatch(updateEmotion(emotion)),
  }
}

const mapStateToProps: MapStateToProps<ConnectedProps, OwnProps, RootState> = (
  state: RootState
) => ({
  isLogin: state.isLogin,
  isCameraReady: state.isCameraReady,
  isCapturing: state.isCapturing,
  user: state.user,
  webcamStatus: state.webcamStatus,
})

export default connect(mapStateToProps, mapDispatchToProps)(Cronjob)
