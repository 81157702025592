import { logout as Logout, loginWithEmailPassword } from '../libs/cognito'
import { Auth } from 'aws-amplify'
import * as Sentry from '@sentry/browser'
import { START_STOP_BTN } from './camera'
import { AWSUserInfo } from '../reducer'
import { AppDispatch } from '../store'

export const USER_LOGIN_START = 'USER_LOGIN_START'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED'
export const USER_LOGOUT = '  USER_LOGOUT'

export const EMOTION_CHANGE = 'EMOTION_CHANGE'

const setupSentry = (user: AWSUserInfo) => {
  Sentry.setUser({
    id: user.id,
    email: user.attributes.email,
    company: user.attributes['custom:company'],
    admin: user.attributes['custom:admin'],
    mode: user.attributes['custom:mode'],
  })
}

const loginSuccessCallback = async (dispatch: AppDispatch) => {
  const currentUser: AWSUserInfo = await Auth.currentUserInfo() //currentAuthenticatedUser();
  setupSentry(currentUser)

  dispatch({
    type: USER_LOGIN_SUCCESS,
    data: currentUser.attributes,
  })
}

export const userLoginUsingEmailPassword = (
  username: string,
  password: string
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: USER_LOGOUT,
      })
      await Logout()
    } catch (error) {
      console.log(error)
    }
    dispatch({
      type: USER_LOGIN_START,
    })

    loginWithEmailPassword(username, password)
      .then(async (user) => {
        loginSuccessCallback(dispatch)
      })
      .catch((err) => {
        dispatch({
          type: USER_LOGIN_FAILED,
        })
      })
  }
}

export const checkLogin = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const currentUser: AWSUserInfo = await Auth.currentUserInfo() //currentAuthenticatedUser();
      if (currentUser) {
        setupSentry(currentUser)
        dispatch({
          type: USER_LOGIN_SUCCESS,
          data: currentUser.attributes,
        })
      }
    } catch (err) {
      dispatch({
        type: USER_LOGOUT,
      })
    }
  }
}

export const logout = () => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: START_STOP_BTN,
        data: false,
      })
      await Logout()
      Sentry.setUser(null)

      dispatch({
        type: USER_LOGOUT,
      })
    } catch (error) {
      console.log(error)
    }
  }
}

export const updateEmotion = (emotion: any) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: EMOTION_CHANGE,
      data: emotion,
    })
  }
}
