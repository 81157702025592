import { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  UserIcon,
  CameraIcon,
  VideoCameraIcon,
  AcademicCapIcon,
  WifiIcon,
  ArrowRightOnRectangleIcon,
} from '@heroicons/react/24/solid'

import { Icon } from '@mdi/react'

import {
  mdiEmoticonSad,
  mdiEmoticon,
  mdiEmoticonHappy,
  mdiEmoticonNeutral,
  mdiEmoticonFrown,
} from '@mdi/js'

import { MODEL_LOADED } from './constant'
import {
  loadLandmarkModel,
  loadVPPGModel,
  loadStressModel,
  modelReady,
} from './actions/model'
import { logout } from './actions/user'
import { disconnectIoT } from './actions/iot'

import AWS from './components/AWS'
import Camera from './components/Camera'
import StartStopBtn from './components/StartStopBtn'
import Cronjob from './components/Cronjob'

function App(props: any) {
  useEffect(() => {
    if (props.isLogin) {
      props.loadLandmarkModel()
      props.loadVPPGModel()
      props.loadStressModel()
    }
  }, [props.isLogin])

  useEffect(() => {
    if (
      props.landmarkModelStatus == MODEL_LOADED &&
      props.vppgModelStatus == MODEL_LOADED &&
      props.stressModelStatus == MODEL_LOADED
    ) {
      props.modelReady()
    }
  }, [
    props.landmarkModelStatus,
    props.vppgModelStatus,
    props.stressModelStatus,
  ])

  const getIconData = (type: any) => {
    switch (type) {
      case 3:
        return {
          icon: mdiEmoticonSad,
          color: "text-blue-500",
        };
      case 4:
        return {
          icon: mdiEmoticonFrown,
          color: "text-red-500",
        };
      case 2:
        return {
          icon: mdiEmoticon,
          color: "text-yellow-500",
        };
      case 1:
        return {
          icon: mdiEmoticonHappy,
          color: "text-green-400",
        };
      default:
        return {
          icon: mdiEmoticonNeutral,
          color: 'text-gray-500',
        }
    }
  }

  const EmotionIcon = () => {
    return (
      <>
        <div className="tooltip">
          <Icon
            className={`h-10 w-10 ${getIconData(props.latestEmotion).color}`}
            path={getIconData(props.latestEmotion).icon}
          />
          <span className="tooltiptext">{props.latestEmotion}</span>
        </div>
      </>
    )
  }

  return (
    <div
      className={`App z-49 bg-gray-900 min-h-screen bg-cover bg-[url('https://vpgaccdemo-web.project-olive.info/img/bg.32224efa.png')]`}
    >
      <div className="md:flex p-2 items-center justify-between">
        <StartStopBtn />
        <div>Build ver.23.09.18</div>
        <div className="icon flex space-x-4">
          {EmotionIcon()}
          <div className="tooltip">
            <UserIcon
              className={`h-10 w-10 ${
                props.isLogin ? 'text-green-500' : 'text-gray-300'
              }`}
             />
          </div>
          <div className="tooltip">
            <AcademicCapIcon
              className={`h-10 w-10 tooltip ${
                props.modelReady ? 'text-green-500' : 'text-gray-300'
              }`}
             />
          </div>
          <div className="tooltip">
            <CameraIcon
              className={`h-10 w-10 tooltip ${
                props.webcamStatus ? 'text-green-500' : 'text-gray-300'
              }`}
             />{' '}
          </div>
          <div className="tooltip">
            <VideoCameraIcon
              className={`h-10 w-10 tooltip ${
                props.isCameraReady ? 'text-green-500' : 'text-gray-300'
              }`}
             />
          </div>
          <div className="tooltip">
            <WifiIcon
              className={`h-10 w-10 tooltip ${
                props.iotConnectionStatus ? 'text-green-500' : 'text-gray-300'
              }`}
             />
          </div>
          <div className="tooltip">
            {props.isLogin && (
              <>
                <ArrowRightOnRectangleIcon
                  onClick={() => {
                    props.disconnectIoT()
                    props.logout()
                  }}
                  className={`h-10 w-10`}
                 />
                <span className="tooltiptext">ろぐあうと</span>
              </>
            )}
          </div>
        </div>
      </div>
      <AWS />
      {props.isCaptureReady && props.isLogin ? (
        <>
          {props.isCameraReady && (<Camera />)}
          <Cronjob />
        </>
      ) : (() => {
        // @ts-ignore
        if (documentPictureInPicture.window) {
          // @ts-ignore
          documentPictureInPicture.window.close();
        }
        return null
      })()}
    </div>
  )
}

const mapDispatchToProps = (dispatch: any, state: any) => {
  return {
    loadLandmarkModel: () => dispatch(loadLandmarkModel()),
    loadVPPGModel: () => dispatch(loadVPPGModel()),
    loadStressModel: () => dispatch(loadStressModel()),
    modelReady: () => dispatch(modelReady()),
    logout: () => dispatch(logout()),
    disconnectIoT: () => dispatch(disconnectIoT()),
  }
}

const mapStateToProps = (state: any) => {
  return {
    isLogin: state.isLogin,
    landmarkModelStatus: state.landmarkModelStatus,
    vppgModelStatus: state.vppgModelStatus,
    stressModelStatus: state.stressModelStatus,
    isCaptureReady: state.isCaptureReady,
    isCameraReady: state.isCameraReady,
    webcamStatus: state.webcamStatus,
    iotConnectionStatus: state.iotConnectionStatus,
    latestEmotion: state.latestEmotion,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
