import { loadLayersModel, LayersModel, Tensor, tensor } from '@tensorflow/tfjs'
import { ProcessSignal } from './DSP'

export class StressProcessor {
  stressModel: LayersModel | null

  constructor() {
    this.stressModel = null
  }

  loadModel = async () => {
    if (this.stressModel === null) {
      this.stressModel = await loadLayersModel(
        `${process.env.PUBLIC_URL}/models/stress/model.json`
      )
      console.log('stress model loaded')
    }
    return true
  }

  predict = async (samples: any, fs: number) => {
    var w = 4
    var s = 2
    var new_fs = 128
    const result = ProcessSignal(samples, fs, new_fs, w, s)

    var input_signal = await tensor(result)

    const final = await (
      this.stressModel?.predictOnBatch(input_signal) as Tensor
    ).data()

    let finalSum = 0
    final.forEach((v) => {
      finalSum += v
    })

    return final.length > 0
      ? Math.round((finalSum / final.length) * 100) / 100
      : 0
  }
}

export default new StressProcessor()
