import { Auth } from 'aws-amplify'
import { IoT } from '@aws-sdk/client-iot'

import { AWS_REGION, IOT_ENDPOINT, IOT_POLICY_NAME } from '../constant'

export const attachPolicy = async (id: string): Promise<void> => {
  const myCredentials = await Auth.currentCredentials()

  const myIoT = new IoT({
    region: AWS_REGION,
    apiVersion: '2015-05-28',
    endpoint: IOT_ENDPOINT,
    credentials: myCredentials,
  })

  const params = { policyName: IOT_POLICY_NAME, target: id }

  try {
    const data = await myIoT.attachPolicy(params)
    console.log('Successfully attached policy with the identity', data)
  } catch (err) {
    console.error(err)
  }
}

export const loginWithEmailPassword = async (
  username: string,
  password: string
): Promise<void> => {
  const user = await Auth.signIn(username, password)
  return user
}

export const logout = async (): Promise<void> => {
  await Auth.signOut()
}
