import { head_shaking_count } from './shaking'

export interface AttentionData {
  time: number
  pose: number
  face: number
  yawning?: number
  blinking: number
}

const MAX_DATA = 1000

class AttentionStore {
  data: AttentionData[]

  constructor() {
    this.data = []
  }

  add(data: AttentionData) {
    if (this.data.length >= MAX_DATA) {
      this.data.shift()
    }

    this.data.push(data)
  }

  query(startTime?: any, endTime?: any) {
    endTime = endTime ?? new Date().getTime()
    startTime = startTime ?? endTime - 60 * 1000

    return this.data.filter((value) => {
      return value.time > startTime && value.time < endTime
    })
  }

  stat(startTime?: any, endTime?: any) {
    const data = this.query(startTime, endTime)
    const l = data.length
    const total_second = Math.round((endTime - startTime) / 1000)

    let face = 0
    let blinking = 0
    let allPoses: any = []

    data.forEach(({ pose, face: faceVal, blinking: blinkingVal }) => {
      allPoses.push(pose)
      face += faceVal
      blinking += blinkingVal
    })

    const headStability = head_shaking_count(allPoses, total_second)

    return [
      Math.round((face / l) * 100) / 100,
      Math.round((blinking / l) * 100) / 100,
      headStability,
      l,
    ]
  }
}

export default new AttentionStore()
