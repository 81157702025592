import { connect, ConnectedProps } from 'react-redux'
import {
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react'
import Webcam from 'react-webcam'
import * as tf from '@tensorflow/tfjs'
import { useWakeLock } from 'react-screen-wake-lock'
import * as Sentry from '@sentry/react'

import { WEBCAM_WIDTH, WEBCAM_HEIGHT, MAX_FPS, SENTRY_TRANSACTION_PUSH_VIDEO, COLLECT_VIDEO } from '../constant'
import { attention } from '../libs/attention'
import tensorStore from '../libs/tensorStore'
import FPSProcessor from '../libs/FPSProcessor'
import vppgPreprocessor from '../libs/vppgPreprocessor'
import {
  changeWebcamStatus,
  startCapture,
  stopCapture,
} from '../actions/camera'
import faceLandmark from '../libs/faceLandmark'
import { AppDispatch, RootState } from '../store'
import { useUploadQueue } from '../libs/minio'

const PIP_DOCUMENT_CONTAINER_ID = 'pip-video-container'
const PIP_DOCUMENT_CONTENT_ID = 'pip-content-id'

type OwnProps = {}

type Props = PropsFromRedux & OwnProps

const Camera = ({
  changeWebcamStatus,
  startCapture,
  stopCapture,
  isCapturing,
  user,
  latestEmotion,
}: Props) => {
  const webcamRef = useRef<Webcam>(null)
  const [webcamRunning, setWebcamStatus] = useState(false)
  const [showCamera, toggleCamera] = useState(false)
  const reqidRef = useRef<number>(0)
  const reqidReqRef = useRef<number>(0)
  const [deviceId, setDeviceId] = useState<string>()
  const [devices, setDevices] = useState<ReadonlyArray<MediaDeviceInfo>>([])
  const { request, release } = useWakeLock()
  const lastFrameTime = useRef(0)
  const currentDeviceId = useRef('')

  const pushUploadQueue = useUploadQueue(user, [webcamRunning])

  // For media recorder
  const mediaRecorderRef = useRef<MediaRecorder | null>(null)

  const handleFrame = useCallback(async (currentTime: number) => {
    const frameInterval = 1000 / MAX_FPS
    const timeSinceLastFrame = currentTime - lastFrameTime.current

    if (timeSinceLastFrame >= frameInterval) {
      const webcamCanvas = webcamRef.current?.getCanvas()
      if (webcamCanvas) {
        const frameAsImageData = webcamCanvas
          .getContext('2d', { willReadFrequently: true })
          ?.getImageData(0, 0, webcamCanvas.width, webcamCanvas.height)!
        try {
          if (reqidReqRef.current === 3) {
            await Promise.all([
              ppgProcess(frameAsImageData),
              attentionProcess(frameAsImageData),
              FPSProcessor.logCaptureSuccess(),
            ])
            reqidReqRef.current = 0
          } else {
            await Promise.all([
              ppgProcess(frameAsImageData),
              FPSProcessor.logCaptureSuccess(),
            ])
            reqidReqRef.current += 1
          }
        } catch (error) {
          console.log(error)
        }
      }
      lastFrameTime.current = currentTime
    }
    reqidRef.current = requestAnimationFrame(handleFrame)
  }, [])

  useEffect(() => {
    if (webcamRunning && isCapturing) {
      vppgPreprocessor.stopProcess()
      vppgPreprocessor.startProcess()
      changeWebcamStatus(true)
    } else {
      vppgPreprocessor.stopProcess()
      changeWebcamStatus(false)
    }
  }, [changeWebcamStatus, isCapturing, webcamRunning])

  useEffect(() => {
    if (webcamRunning && isCapturing) {
      requestAnimationFrame(handleFrame)
      return () => cancelAnimationFrame(reqidRef.current)
    }
  }, [webcamRunning, isCapturing, handleFrame])

  const enumCameras = useCallback(async () => {
    const mds = (await navigator.mediaDevices.enumerateDevices()).filter(({ kind }) => kind === 'videoinput')
    setDevices(mds)
    if (mds.length > 0) {
      setDeviceId(mds[0].deviceId)
    }
  }, [])
  // initial enumerate
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { enumCameras() }, [])

  const startRecorder = useCallback(() => {
    if (webcamRef.current?.stream != null && COLLECT_VIDEO) {
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: 'video/webm',
      })
      mediaRecorderRef.current.addEventListener('dataavailable', async ({ data }: BlobEvent) => {
        if (!data.size) return
        const transaction = Sentry.startTransaction({
          name: SENTRY_TRANSACTION_PUSH_VIDEO,
        })
        pushUploadQueue(data, '.webm', new Date(), () => transaction.finish())
      })
      mediaRecorderRef.current.start()

      // split 10 minutes
      const intervalTime = 1000 * 60 * 10
      let targetTime = Date.now() + intervalTime
      const intervalId = setInterval(() => {
        if (mediaRecorderRef.current?.state !== 'recording') {
          clearInterval(intervalId)
          mediaRecorderRef.current = null
          return
        }
        if (Date.now() < targetTime) return

        // MediaRecorder.requestData only contains pure intermediate data without file header in the blob, so incomplete data is sent
        // Therefore, blob data including header is saved by "stop & start"
        const restart = () => {
          console.log('restart')
          mediaRecorderRef.current?.start()
          mediaRecorderRef.current?.removeEventListener('stop', restart)
        }
        mediaRecorderRef.current.addEventListener('stop', restart)
        mediaRecorderRef.current.stop()

        // Set next interval
        targetTime = Date.now() + intervalTime
      }, 1000)
    }
  }, [pushUploadQueue])

  const handleCaptureClick = async () => {
    const pipDocument = document.getElementById(PIP_DOCUMENT_CONTENT_ID)!
    // @ts-expect-error
    const pipWindow = await documentPictureInPicture.requestWindow();
    pipWindow.document.body.append(pipDocument);

    await request()
    await startCapture()
    pipWindow.addEventListener('pagehide', async (event: PageTransitionEvent) => {
      // restore window
      const container = document.getElementById(PIP_DOCUMENT_CONTAINER_ID)!;
      // @ts-expect-error
      const pipContent = event.target.getElementById(PIP_DOCUMENT_CONTENT_ID)!;
      container.append(pipContent);

      // stop recorder
      mediaRecorderRef.current?.stop()

      await stopCapture()
      await release()
    })

    // start recorder
    startRecorder()
  }

  // If the camera is no longer accessible, continue enumerating until it is accessible.
  const enumCamerasIntervalId = useRef<NodeJS.Timer>()
  useEffect(() => {
    if (webcamRunning) {
      if (enumCamerasIntervalId.current) {
        clearInterval(enumCamerasIntervalId.current)
        enumCamerasIntervalId.current = undefined
      }
      return
    }

    if (enumCamerasIntervalId.current) {
      return
    }

    enumCamerasIntervalId.current = setInterval(() => enumCameras(), 1000 * 5) // 5s

    return () => {
      if (enumCamerasIntervalId.current) {
        clearInterval(enumCamerasIntervalId.current)
      }
    }
  }, [enumCameras, webcamRunning])

  return (
    <div
      className={`h-[calc(100vh-70px)] z-40 top-[40px] right-0 w-full bg-contain bg-top bg-no-repeat bg-center z-40 ${
        isCapturing ? "bg-[url('/public/02.png')]" : ''
      }`}
    >
      <div className="space-x-2 fixed top-[40px] right-0 z-40" />
      <div className="relative h-full overflow-hidden text-right space-x-4">
        <button
          onClick={() => toggleCamera((prv) => !prv)}
          className="p-1 text-white rounded cursor-pointer bg-blue-500 hover:bg-blue-900"
        >
          かめらをひょうじ: {showCamera ? 'On' : 'Off'}
        </button>
        {devices.map((device, i) => {
          if (!deviceId && i === 0) {
            return (
              <button
                className={`p-1 text-white rounded cursor-pointer bg-blue-500 hover:bg-blue-900`}
                key={device.deviceId}
                onClick={() => {
                  setDeviceId(device.deviceId)
                  currentDeviceId.current = device.deviceId
                }}
              >
                {device.label || `Device ${i + 1}`}
              </button>
            )
          } else
            return (
              <button
                className={`p-1 text-white rounded cursor-pointer hover:bg-blue-500 ${
                  deviceId === device.deviceId ? 'bg-blue-500' : 'bg-gray-500'
                }`}
                key={device.deviceId}
                onClick={() => {
                  setDeviceId(device.deviceId)
                  currentDeviceId.current = device.deviceId
                }}
              >
                {device.label || `Device ${i + 1}`}
              </button>
            )
        })}
        {!isCapturing && (
          <div
            className="text-center"
            style={{
              position: 'absolute',
              top: '20%',
              left: 0,
              right: 0,
            }}
          >
            <button
              className="font-black p-1 text-9xl text-white rounded cursor-pointer bg-blue-500 hover:bg-blue-900"
              onClick={handleCaptureClick}
              style={{
                width: WEBCAM_WIDTH,
                height: WEBCAM_HEIGHT,
              }}
            >
              けいそくを
              <br />
              はじめる
            </button>
          </div>
        )}
        <Webcam
          className={`ml-auto z-50 ${!showCamera ? 'invisible' : ''}`}
          audio={false}
          width={WEBCAM_WIDTH}
          height={WEBCAM_HEIGHT}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={{
            width: WEBCAM_WIDTH,
            height: WEBCAM_HEIGHT,
            deviceId,
            frameRate: {
              ideal: MAX_FPS,
              max: MAX_FPS,
            },
          }}
          onUserMedia={(str) => {
            setWebcamStatus(true)

            // Detect PC sleep etc.
            str.addEventListener('inactive', (e) => {
              if (currentDeviceId.current === '') {
                setWebcamStatus(false)
                setDeviceId(undefined)
                setDevices([])
                enumCameras()
              }
              currentDeviceId.current = ''
            })

            if (isCapturing && mediaRecorderRef.current == null) {
              console.log('restart capture!!!')
              startRecorder()
            }
          }}
          onUserMediaError={() => setWebcamStatus(false)}
        />
        <div id={PIP_DOCUMENT_CONTAINER_ID}>
          <div id={PIP_DOCUMENT_CONTENT_ID} className="invisible" style={{
            height: isCapturing ? '256px' : '100%',
            border: '5px solid',
            borderColor: colorFromLastEmotion(latestEmotion),
          }}>
            <img
              src="/pip.png"
              alt=""
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const ppgProcess = (frameAsImageData: ImageData) => {
  const origV = tf.tidy(() => {
    const imageTensor = tf.browser
      .fromPixels(frameAsImageData)
      .expandDims<tf.Tensor3D>(0)

    const crop = tf.image.resizeBilinear(imageTensor, [36, 36])
    const origV = crop.reshape<tf.Tensor3D>([36, 36, 3])
    return origV
  })

  tensorStore.addRawTensor(origV)
  vppgPreprocessor.process()
}

const attentionProcess = async (frameAsImageData: ImageData) => {
  try {
    const predictions = await faceLandmark.model.estimateFaces(
      frameAsImageData,
      {
        staticImageMode: false,
      }
    )
    attention(predictions)
  } catch (error) {
    console.log(error)
    console.log('estimate_failed')
  }
}

const colorFromLastEmotion = (emotion: number) => {
  switch (emotion) {
    case 3:
      return 'rgb(59 130 246)'
    case 4:
      return 'rgb(239 68 68)'
    case 2:
      return 'rgb(234 179 8)'
    case 1:
      return 'rgb(74 222 128)'
    default:
      return 'rgb(107 114 128)'
  }
}

const mapDispatchToProps = (
  dispatch: AppDispatch
) => {
  return {
    changeWebcamStatus: (isWorking: boolean) =>
      dispatch(changeWebcamStatus(isWorking)),
    startCapture: () => dispatch(startCapture()),
    stopCapture: () => dispatch(stopCapture()),
  }
}

const mapStateToProps = (
  state: RootState
) => ({
  isCapturing: state.isCapturing,
  user: state.user,
  latestEmotion: state.latestEmotion,
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Camera)
