import { dispose, Tensor3D, TypedArray } from '@tensorflow/tfjs-core'
import { CAPTURE_FREQUENCY, BATCHSIZE } from '../constant'

export interface TensorStoreInterface {
  reset(): void
  getRawTensor(): Tensor3D | undefined
  addRppgPltData(data: TypedArray): void
  addRawTensor(data: Tensor3D): void
}

class TensorStore implements TensorStoreInterface {
  rawFrames: Set<Tensor3D>
  rppgPltData: Float32Array
  rppgPltDataIndex: number

  constructor() {
    this.rawFrames = new Set<Tensor3D>()
    this.rppgPltData = new Float32Array(CAPTURE_FREQUENCY * 60)
    this.rppgPltDataIndex = 0
  }

  reset = () => {
    for (const frame of this.rawFrames) {
      dispose(frame)
    }
    this.rawFrames.clear()
    this.rppgPltDataIndex = 0
  }

  getRawTensor = () => {
    const tensor = this.rawFrames.values().next().value
    if (tensor) {
      this.rawFrames.delete(tensor)
    }
    return tensor
  }

  addRppgPltData = (data: TypedArray) => {
    for (let i = 0; i < data.length; i++) {
      this.rppgPltData[this.rppgPltDataIndex] = data[i]
      this.rppgPltDataIndex =
        (this.rppgPltDataIndex + 1) % this.rppgPltData.length
    }
  }

  addRawTensor = (tensor: Tensor3D) => {
    this.rawFrames.add(tensor)
  }
}

export default new TensorStore()
