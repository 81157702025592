import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'

import { userLoginUsingEmailPassword, checkLogin } from '../actions/user'
import { connectIoT, disconnectIoT } from '../actions/iot'
import { ArrowPathIcon } from '@heroicons/react/24/solid'

const AWS = (props: any) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    props.checkLogin()
  }, [])

  useEffect(() => {
    if (props.isLogin) {
      props.connectIoT()
    } else {
      props.disconnectIoT()
    }
  }, [props.isLogin])

  if (props.isLogin) {
    return <></>
  }

  const showShowPasswordHandler = () => {
    setShowPassword((showPassword) => !showPassword)
  }

  return (
    <>
      <div className="form max-w-[500px] mx-auto space-y-6 px-12 mt-6 rounded-lg py-12 text-white bg-gray-900/50">
        <div className="logo">
          <img
            src="https://vpgaccdemo-web.project-olive.info/img/logo_vertical.7e81cb96.svg"
            alt=""
            className="mx-auto w-1/2"
          />
        </div>
        <div className="title py-6 text-2xl text-center">ろぐいん</div>
        <div className="username rounded-full overflow-hidden flex bg-gray-600">
          <input
            className="w-full bg-gray-600 p-3 px-6 outline-none"
            type="text"
            id="username"
            placeholder="めーるあどれす"
            value={username || ''}
            onChange={(event) => setUsername(event.target.value)}
          />
        </div>
        <div className="password rounded-full overflow-hidden flex bg-gray-600 items-center">
          <input
            className="w-full p-3 px-6 outline-none bg-gray-600"
            type={showPassword ? 'text' : 'password'}
            id="password"
            placeholder="ぱすわーど"
            value={password || ''}
            onChange={(event) => setPassword(event.target.value)}
          />
          {showPassword ? (
            <EyeIcon
              className="h-6 w-6 mr-4 hover:cursor-pointer"
              onClick={showShowPasswordHandler}
            ></EyeIcon>
          ) : (
            <EyeSlashIcon
              className="h-6 w-6 mr-4 hover:cursor-pointer"
              onClick={showShowPasswordHandler}
            ></EyeSlashIcon>
          )}
        </div>
        <button
          className="outline-none p-3 text-white bg-blue-600 hover:cursor-pointer rounded-full w-full disabled:bg-gray-600"
          disabled={!username || !password}
          onClick={() => {
            props.userLoginUsingEmailPassword(username, password)
          }}
        >
          <div className="mx-auto w-fit flex items-center">
            {props.loginLoading && (
              <ArrowPathIcon className="h-6 w-6 animate-spin" />
            )}
            <span>ろぐいん</span>
          </div>
        </button>
        {props.loginFailed && (
          <span className="text-red-500 text-center my-2">
            めーるあどれす か ぱすわーどがちがいます
          </span>
        )}
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch: any, state: any) => {
  return {
    connectIoT: () => dispatch(connectIoT()),
    disconnectIoT: () => dispatch(disconnectIoT()),
    userLoginUsingEmailPassword: (username: string, password: string) => {
      dispatch(userLoginUsingEmailPassword(username, password))
    },
    checkLogin: () => {
      dispatch(checkLogin())
    },
  }
}

const mapStateToProps = (state: any) => ({
  isLogin: state.isLogin,
  iotConnectionStatus: state.iotConnectionStatus,
  loginLoading: state.loginLoading,
  loginFailed: state.loginFailed,
})

export default connect(mapStateToProps, mapDispatchToProps)(AWS)
