import Fili from 'fili'
import * as waveResampler from 'wave-resampler'
import * as math from 'mathjs'

const ProcessSignal = (
  samples: any,
  fs: number,
  new_fs: number,
  w: number,
  s: number
) => {
  const custom_diff = (arrs: any) => {
    return arrs.map((arr: []) => {
      let local_res = []
      const l = arr.length
      local_res = arr.slice(0, l - 1).map((value: any, index: number) => {
        return arr[index + 1] - arr[index]
      })
      return local_res
    })
  }

  const custom_np_append = (a: any, b: any) => {
    var result: any = []
    a.map((x: any, i: any) => {
      result.push(x.concat(b[i]))
    })
    return result
  }

  const custom_np_dstack = (a: any, b: any) => {
    var result: any = []
    a.map((x: any, i: any) => {
      var local: any = []
      x.map((y: any, j: any) => {
        local.push([a[i][j], b[i][j]])
      })
      result.push(local)
    })
    return result
  }

  var new_data: any = waveResampler.resample(samples, fs, new_fs, {
    method: 'linear',
  })

  var sliced_data: any = []
  var total_step = new_data.length - w * new_fs
  for (var i = 0; i < total_step; i = i + new_fs * s) {
    sliced_data.push(Array.from(new_data.slice(i, i + w * new_fs)))
  }

  const diff_x = custom_diff(sliced_data)

  const sliced_data_reshaped = math.reshape(
    sliced_data.map((x: any) => x[0]),
    [-1, 1]
  )

  var diff_sliced_data = custom_np_append(sliced_data_reshaped, diff_x)
  var full_data = custom_np_dstack(sliced_data, diff_sliced_data)
  return full_data
}

const iirCalculator = new Fili.CalcCascades()
const iirFilterCoeffs = iirCalculator.bandpass({
  order: 1, // cascade 3 biquad filters (max: 12)
  characteristic: 'butterworth',
  Fs: 30, // sampling frequency
  Fc: 1.375, // (2.5-0.75) / 2 + 0.75, 2.5 --> 150/60, 0.75 --> 45/60 # 1.625
  BW: 1.25, // 2.5 - 0.75 = 1.75
  gain: 0, // gain for peak, lowshelf and highshelf
  preGain: false, // adds one constant multiplication for highpass and lowpass
})
const iirFilter = new Fili.IirFilter(iirFilterCoeffs)

export { iirFilter, ProcessSignal }
