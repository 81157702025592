import LandmarkModel from '../libs/faceLandmark'
import vppgPosprocessor from '../libs/vppgPosprocessor'
import stressProcessor from '../libs/stressProcessor'
import { AppDispatch } from '../store'

export const LOAD_VPPG_MODEL_START = 'LOAD_VPPG_MODEL_START'
export const LOAD_VPPG_MODEL_SUCCESS = 'LOAD_VPPG_MODEL_SUCCESS'
export const LOAD_VPPG_MODEL_FAILED = 'LOAD_VPPG_MODEL_FAILED'

export const LOAD_LANDMARK_MODEL_START = 'LOAD_LANDMARK_MODEL_START'
export const LOAD_LANDMARK_MODEL_SUCCESS = 'LOAD_LANDMARK_MODEL_SUCCESS'
export const LOAD_LANDMARK_MODEL_FAILED = 'LOAD_LANDMARK_MODEL_FAILED'

export const LOAD_STRESS_MODEL_START = 'LOAD_STRESS_MODEL_START'
export const LOAD_STRESS_MODEL_SUCCESS = 'LOAD_STRESS_MODEL_SUCCESS'
export const LOAD_STRESS_MODEL_FAILED = 'LOAD_STRESS_MODEL_FAILED'

export const MODEL_READY = 'MODEL_READY'

export const loadLandmarkModel = () => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: LOAD_LANDMARK_MODEL_START,
    })

    try {
      await LandmarkModel.loadModel()
      dispatch({
        type: LOAD_LANDMARK_MODEL_SUCCESS,
      })
    } catch (error) {
      console.error('Failed to load landmark model:', error)
      dispatch({
        type: LOAD_LANDMARK_MODEL_FAILED,
      })
    }
  }
}

export const loadVPPGModel = () => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: LOAD_VPPG_MODEL_START,
    })

    vppgPosprocessor
      .loadModel()
      .then(() => {
        dispatch({
          type: LOAD_VPPG_MODEL_SUCCESS,
        })
      })
      .catch(() => {
        dispatch({
          type: LOAD_VPPG_MODEL_FAILED,
        })
      })
  }
}

export const loadStressModel = () => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: LOAD_STRESS_MODEL_START,
    })

    stressProcessor
      .loadModel()
      .then(() => {
        dispatch({
          type: LOAD_STRESS_MODEL_SUCCESS,
        })
      })
      .catch(() => {
        dispatch({
          type: LOAD_STRESS_MODEL_FAILED,
        })
      })
  }
}

export const modelReady = () => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: MODEL_READY,
    })
  }
}
