import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { startStop } from '../actions/camera'
import { ArrowPathIcon } from '@heroicons/react/24/solid'

const autoStart = true

function StartStopBtn(props: any) {
  const [isStartable, setStartable] = useState(false)
  const [userEmail, setUserMail] = useState('')

  useEffect(() => {
    if (props.isCaptureReady && props.isLogin) {
      setStartable(true)
    } else {
      setStartable(false)
    }
  }, [props.isCaptureReady, props.isLogin])

  useEffect(() => {
    if (autoStart && isStartable) {
      props.startStop(true)
    }
  }, [isStartable])

  useEffect(() => {
    if (props.isLogin) {
      setUserMail(props.user.email)
    }
  }, [props.user])

  if (!props.isLogin) return <div></div>

  if (!isStartable) {
    return (
      <>
        <ArrowPathIcon className="h-6 w-6 animate-spin" />
      </>
    )
  }

  return <>{userEmail && <div className="pl-2">{userEmail}</div>}</>
}

const mapDispatchToProps = (dispatch: any, state: any) => {
  return {
    startStop: (capture: boolean) => dispatch(startStop(capture)),
  }
}

const mapStateToProps = (state: any) => {
  return {
    isCameraReady: state.isCameraReady,
    isLogin: state.isLogin,
    isCaptureReady: state.isCaptureReady,
    user: state.user,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StartStopBtn)
