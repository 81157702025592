import * as tf from '@tensorflow/tfjs'
import * as faceLandmarksDetection from '@tensorflow-models/face-landmarks-detection'
import { VERSION } from '@mediapipe/face_mesh'

class LandmarkModel {
  model: faceLandmarksDetection.FaceLandmarksDetector
  isModelLoaded: boolean

  constructor() {
    this.model = {} as faceLandmarksDetection.FaceLandmarksDetector
    this.isModelLoaded = false
  }

  async loadModel() {
    await tf.ready()
    const model = faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh
    const detectorConfig: faceLandmarksDetection.MediaPipeFaceMeshMediaPipeModelConfig =
      {
        runtime: 'mediapipe', // or 'tfjs'
        solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh@${VERSION}`,
        refineLandmarks: false,
        maxFaces: 1,
      }
    const detector = await faceLandmarksDetection.createDetector(
      model,
      detectorConfig
    )
    this.model = detector
    this.isModelLoaded = true
    console.log('face landmarkModel loaded')
  }
}

export default new LandmarkModel()
