export const MAX_FPS = 35
export const BATCHSIZE = 10
export const CAPTURE_FREQUENCY = 30
export const WEBCAM_WIDTH = 640
export const WEBCAM_HEIGHT = 430
export const identityPoolId =
  'ap-northeast-1:b94e1c1f-8c67-4500-8644-2c8c635d43ad'
export const AWS_REGION = 'ap-northeast-1'
export const userPoolId = 'ap-northeast-1_h6Wcjb8F7'
export const userPoolWebClientId = '53hf72b9dltc4f4bc8rpngbt2f'
export const MQTT_ENDPOINT =
  'wss://a2om7uz4m115jp-ats.iot.ap-northeast-1.amazonaws.com/mqtt'
export const IOT_ENDPOINT = 'https://iot.ap-northeast-1.amazonaws.com'
export const IOT_POLICY_NAME = 'prd_lacause_emotion_prediction_results_v1'
export const IOT_TOPIC = 'prd_lacause_emotion_prediction_results_v1'

export const FOUR_TYPES_API =
  'https://rv041tmaa4.execute-api.ap-northeast-1.amazonaws.com/prd/app/GetTimeStreamData'

export const BLINKING_THRESHOLDED = 0.21
export const YAWNING_THRESHOLDED = 0.79
export const HEADPOSE_THRESHOLDED = 60

export const MODEL_NOT_LOAD = 0
export const MODEL_LOADING = 1
export const MODEL_LOADED = 2
export const MODEL_LOAD_FAILED = 3

export const MINIO_ENDPOINT = 'https://10.31.36.211:9000'
export const BACKUP_BUCKET = 'prd-lacause-rawsignal-data'
export const COLLECT_PULSE = true
export const COLLECT_VIDEO = false

// For Sentry
export const SENTRY_DSN =
  'https://295e980328bf40d7bc9b209b80ea3029@o4505487114829824.ingest.sentry.io/4505487132262400'
export const SENTRY_TRANSACTION_PUSH_IOT = 'push-iot'
export const SENTRY_TRANSACTION_PUSH_VIDEO = 'push-video'

export const SENTRY_CUSTOM_METRICS_FPS = 'fps'
export const SENTRY_CUSTOM_METRICS_RAW_SIGNAL_LENGTH = 'raw_signal_length'
export const SENTRY_CUSTOM_METRICS_SUCCEED = 'capture_succeed'
